
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import PersistentDialog from "@aprocon-software/aprocon-main-components";


@Component({
    components: {
        PersistentDialog
    }
})
export default class App extends Vue {

    private gotoTop(): void {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    private mainMenuOpen: boolean = false;
    private overrideList: boolean = false;
    private importDialog: boolean = false;
    private importFile: any = null;

    private closeImport(): void {
        this.mainMenuOpen = false;
        this.importDialog = false;
    }

    private importList(): void {
        this.mainMenuOpen = false;
        if (this.importFile) {
            let reader = new FileReader();

            // Use the javascript reader object to load the contents
            // of the file in the v-model prop
            reader.readAsText(this.importFile);
            reader.onload = () => {
                try {
                    const jsonList = reader?.result?.toString();
                    if (jsonList) {
                        const list = JSON.parse(jsonList);
                        console.log(list.length);
                        if (list.length === 0) {
                            this.messageDialog.showWarning("Import", "Es konnte keine Bücherliste in der angegeben Datei gefunden werden");
                        } else {
                            this.messageDialog.showConfirmation("Import", "Bist Du sicher, dass Du die Liste importieren möchtest", () => {
                                if (this.overrideList) {
                                    this.$store.commit("Base/setBooks", list);
                                } else {
                                    const currentBooks = this.$store.getters["Base/books"];
                                    list.forEach((listItem: any) => {
                                        currentBooks.push(listItem);
                                    });
                                    this.$store.commit("Base/setBooks", currentBooks);
                                }
                                this.$router.go(0);
                            });
                        }
                    } else {
                        this.messageDialog.showWarning("Import", "Es konnte keine Bücherliste in der angegeben Datei gefunden werden");
                    }
                } catch (e: any) {
                    this.messageDialog.showError("Import", e.message);
                }
            };
        }
    }

    private get disableExport(): boolean {
        return this.$store.getters["Base/books"]?.length <= 0
    }

    private get footerCopyright(): string {
        const now = new Date();
        return "MeineBuecherliste &copy; " + now.getFullYear();
    }

    private logoPath: string = "../img/icons/icon-48.png";
    @Ref()
    private messageDialog!: any;

    private exportBooks(): void {
        this.mainMenuOpen = false;
        try {
            const blob = new Blob([JSON.stringify(this.$store.getters["Base/books"])], {type: "application/json"});
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = "MeineBuecherliste.json";
            document.body.appendChild(link);
            link.click()
            setTimeout(() => {
                window.URL.revokeObjectURL(link.href);
            }, 250);
            document.body.removeChild(link);
            this.messageDialog.showInfo("Export", "Deine Buecherliste wurde erfolgreich exportiert.");
        } catch (err: any) {
            this.messageDialog.showInfo("Export", err.message);
        }
    }

    mounted() {
        window.onscroll = () => this.scrollFunction();
    }

    private scrollFunction() {
        const mybutton = document.getElementById("toTopButton");
        if (mybutton) {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                mybutton.style.display = "block";
            } else {
                mybutton.style.display = "none";
            }
        }
    }
}
