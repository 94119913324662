import { CardPort } from '@/modules/cards/CardPort';
import {CardService} from "@/modules/cards/service/CardService";
import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";
import {CardRepository} from "@/modules/cards/adapter/CardRepository";
import {CardRestRepository} from "@/modules/cards/adapter/rest/CardRestRepository";

export class CardPortFactory {
    private readonly authenticationProvider: AuthenticationProvider;
    private cardsService: CardService | null = null;
    private cardRepository: CardRepository | null = null;

    private constructor(auth: AuthenticationProvider) {
        this.authenticationProvider = auth;
    }

    public static createCardsPort(auth: AuthenticationProvider): CardPort {
        const factory = new CardPortFactory(auth);
        return new CardPort({
            cardsService: factory.getCardsService(),
        });
    }

    private getCardsService(): CardService {
        if (this.cardsService === null) {
            this.cardsService = new CardService({
                cardRepository: this.getCardsRepository(),
            });
        }
        return this.cardsService;
    }

    private getCardsRepository(): CardRepository {
        if (this.cardRepository === null) {
            this.cardRepository = new CardRestRepository(this.authenticationProvider);
        }
        return this.cardRepository;
    }
}
