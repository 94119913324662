import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";
import {LicensingRepository} from "@/modules/licensing/adapter/LicensingRepository";
import GetLicenceInformationResponseEntry
    from "@/assets/js/models/GetLicenseInformationResponseEntry";
import axios from "axios";
import {License} from "@/modules/licensing";


export class LicensingRestRepository implements LicensingRepository {

    private readonly auth: AuthenticationProvider;

    constructor(params: {
        auth: AuthenticationProvider,
    }) {
        this.auth = params.auth;
    }

    public async getLicenseInfo(): Promise<License> {
        const response = await axios.post(`${this.auth.getServiceUrl()}/Authentication/getLicenceInformation`);
        return response.data.getLicenceInformation_response;
    }

}
