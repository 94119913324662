import Vue from 'vue';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

/**
 * Module state
 */
interface State {
    books:string
}

const moduleState: State = {
    books: localStorage.getItem('Base/books')||'',
};

/**
 * Module getters
 */
const moduleGetters: GetterTree<State, any> = {
    books(state: State) {
        if(state.books) {
            return JSON.parse(state.books);
        }
        return [];
    },
};

/**
 * Module mutations
 */
const moduleMutations: MutationTree<State> = {
    setBooks(state: State, books: any[]) {
        state.books = JSON.stringify(books);
    },
};

/**
 * Module actions
 */
const moduleActions: ActionTree<State, any> = {

};

/**
 * Construct Module
 */
const module: Module<State, any> = {
    namespaced: true,
    state: moduleState,
    getters: moduleGetters,
    mutations: moduleMutations,
    actions: moduleActions,
};
export default module;
