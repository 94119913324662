import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/src/locale/de';
import '@fortawesome/fontawesome-free/css/all.css';
import IconComponent from "@/components/IconComponent.vue";

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { de },
        current: 'de',
    },
    icons: {
        iconfont: 'fa',
    },
    theme: {
        dark: false,
        default: "light",
        themes: {
            light: {
    primary: '#607d8b',
    secondary: '#4caf50',
    accent: '#ffeb3b',
    error: '#ff5722',
    warning: '#ffc107',
    info: '#00bcd4',
    success: '#8bc34a'

            },
        },
    },
});
