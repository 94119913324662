import {LicensingPort} from "@/modules/licensing/LicensingPort";
import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";
import {LicensingService} from "@/modules/licensing/services/LicensingService";
import {LicensingRepository} from "@/modules/licensing/adapter/LicensingRepository";
import {LicensingRestRepository} from "@/modules/licensing/adapter/rest/LicensingRestRepository";

export class LicensingPortFactory {
    private readonly authenticationProvider: AuthenticationProvider;
    private licensingService: LicensingService|null = null;
    private licensingRepository: LicensingRepository|null = null;
    private constructor(params: { auth: AuthenticationProvider }) {
        this.authenticationProvider = params.auth;
    }
    private getLicensingService(): LicensingService {
        if (this.licensingService === null) {
            this.licensingService = new LicensingService({
                licensingRepository: this.getLicensingRepository(),
            });
        }
        return this.licensingService;
    }
    private getLicensingRepository(): LicensingRepository {
        if (this.licensingRepository === null) {
            this.licensingRepository = new LicensingRestRepository({
                auth: this.authenticationProvider,
            });
        }
        return this.licensingRepository;
    }
    public static createLicensingPort(auth: AuthenticationProvider): LicensingPort {
        const factory = new LicensingPortFactory({ auth: auth });
        return new LicensingPort({
            licensingService: factory.getLicensingService(),
        });
    }
}
