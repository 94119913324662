import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";
import {ScalesPort} from "@/modules/scales/ScalesPort";
import {ScalesRestRepository} from "@/modules/scales/adapter/rest/ScalesRestRepository";
import {ScalesRepository} from "@/modules/scales/adapter/ScalesRepository";

export class ScalesPortFactory {
    private readonly authenticationProvider: AuthenticationProvider;
    private scalesRepository: ScalesRepository | null = null;

    private constructor(auth: AuthenticationProvider) {
        this.authenticationProvider = auth;
    }

    public static createScalesPort(auth: AuthenticationProvider): ScalesPort {
        const factory = new ScalesPortFactory(auth);
        return new ScalesPort({
            scalesRepository: factory.getScalesRepository(),
        });
    }

    private getScalesRepository(): ScalesRepository {
        if (this.scalesRepository === null) {
            this.scalesRepository = new ScalesRestRepository(this.authenticationProvider);
        }
        return this.scalesRepository;
    }
}
