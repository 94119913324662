import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";

import {CardActionsPort} from "@/modules/cardActions/CardActionsPort";

import {MasterDataRepository} from "@/modules/masterData/adapter/MasterDataRepository";
import {MasterDataPort} from "@/modules/masterData/MasterDataPort";
import {MasterDataRestRepository} from "@/modules/masterData/adapter/rest/MasterDataRestRepository";

export class MasterDataPortFactory {
    private readonly authenticationProvider: AuthenticationProvider;
    private masterDataRepository: MasterDataRepository | null = null;

    private constructor(auth: AuthenticationProvider) {
        this.authenticationProvider = auth;
    }

    public static createMasterDataPort(auth: AuthenticationProvider): MasterDataPort {
        const factory = new MasterDataPortFactory(auth);
        return new MasterDataPort({
            masterDataRepository: factory.getMasterDataRepository(),
        });
    }

    private getMasterDataRepository(): MasterDataRepository {
        if (this.masterDataRepository === null) {
            this.masterDataRepository = new MasterDataRestRepository(this.authenticationProvider);
        }
        return this.masterDataRepository;
    }
}
