import {CardActionsRepository} from "@/modules/cardActions/adapter/CardActionsRepository";

export class CardActionsPort implements CardActionsRepository{
    private readonly cardActionsRepository: CardActionsRepository;

    constructor(params: { cardsActionsRepository: CardActionsRepository }) {
        this.cardActionsRepository = params.cardsActionsRepository;
    }
    public printTransactions(ids:number[]):void {
        this.cardActionsRepository.printTransactions(ids);
    }

    public cancelTransactions(ids: number[]): void {
        this.cardActionsRepository.cancelTransactions(ids);
    }
}
