
import {PosManagerRepository} from "@/modules/posManager/adapter/PosManagerRepository";

export class PosManagerPort implements PosManagerRepository{
    public async sendArticle(mode: number): Promise<boolean> {
        return this.posManagerRepository.sendArticle(mode);
    }

    public async sendMCustomers(mode: number): Promise<boolean> {
        return this.posManagerRepository.sendMCustomers(mode);
    }
    public async receiveTaf(): Promise<boolean> {
        return this.posManagerRepository.receiveTaf();
    }
    public async receivePersonalzeiten(): Promise<boolean> {
        return this.posManagerRepository.receivePersonalzeiten();
    }
    public async sendMasterdata(mode: number): Promise<boolean> {
        return this.posManagerRepository.sendMasterdata(mode);
    }

    public async sendScaleData(mode: number): Promise<boolean> {
        return this.posManagerRepository.sendScaleData(mode);
    }
    private readonly posManagerRepository: PosManagerRepository;

    constructor(params: { posManagerRepository: PosManagerRepository }) {
        this.posManagerRepository = params.posManagerRepository;
    }
    public async getConnectionStatus():Promise<number> {
        return this.posManagerRepository.getConnectionStatus();
    }
    public async isTransmitting():Promise<boolean> {
        return this.posManagerRepository.isTransmitting();
    }

    public async getReports() : Promise<any[]> {
        return this.posManagerRepository.getReports();
    }

    public async getReport(args:{reportNr:number, start:Date, end:Date}): Promise<string> {
        return this.posManagerRepository.getReport(args);
    }

    public async getCashReport(date:string, id:number): Promise<string> {
        return this.posManagerRepository.getCashReport(date,id);
    }

    public async getBonReport(id:number): Promise<string> {
        return this.posManagerRepository.getBonReport(id);
    }

    public async sendArticleList(selected:{RecordId:number}[]): Promise<boolean> {
        return this.posManagerRepository.sendArticleList(selected);
    }

    public getSalesGridList(): any[] {
        return this.posManagerRepository.getSalesGridList();
    }


}
