import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";
import {MasterDataRepository} from "@/modules/masterData/adapter/MasterDataRepository";
import axios from "axios";

export class MasterDataRestRepository implements MasterDataRepository {
    private readonly auth: AuthenticationProvider;
    private masterDataList= [{
        "LABEL" : "Warengruppen",
        "ICON" : "posmanager-types",
        "GRIDNAME" : "BROWSE_WARENGRUPPEN",
        "FORMNAME" : "",
        "DISABLED" : false
    },
        {
            "LABEL" : "Verkaufspersonal",
            "ICON" : "posmanager-users-man-woman",
            "GRIDNAME" : "BROWSE_VERKAUFSPERSONAL",
            "FORMNAME" : "",
            "DISABLED" : false
        },
        {
            "LABEL" : "Preiskategorien",
            "ICON" : "posmanager-tag-price-euro",
            "GRIDNAME" : "BROWSE_PREISKATEGORIEN",
            "FORMNAME" : "",
            "DISABLED" : false
        },
        {
            "LABEL" : "Kundentypen",
            "ICON" : "posmanager-people-customers",
            "GRIDNAME" : "BROWSE_KUNDENTYPEN",
            "FORMNAME" : "",
            "DISABLED" : false
        },
        {
            "LABEL" : "Artikel-Einheiten",
            "ICON" : "posmanager-settings",
            "GRIDNAME" : "BROWSE_EINHEITEN",
            "FORMNAME" : "",
            "DISABLED" : false
        },
        {
            "LABEL" : "Allergene",
            "ICON" : "posmanager-danger",
            "GRIDNAME" : "BROWSE_ALLERGENE",
            "FORMNAME" : "",
            "DISABLED" : false
        },
        {
            "LABEL" : "EAN13-Konfigurationen",
            "ICON" : "posmanager-label-barcode-settings",
            "GRIDNAME" : "BROWSE_EAN13KONFIGURATIONEN",
            "FORMNAME" : "",
            "DISABLED" : false
        },
        {
            "LABEL" : "Texte",
            "ICON" : "posmanager-label-text-font",
            "GRIDNAME" : "BROWSE_ETIKETTENTEXTE",
            "FORMNAME" : "",
            "DISABLED" : false
        },
        {
            "LABEL" : "Tara-Werte",
            "ICON" : "posmanager-settings",
            "GRIDNAME" : "BROWSE_TARAS",
            "FORMNAME" : "",
            "DISABLED" : false
        },
        {
            "LABEL" : "Rabatte",
            "ICON" : "posmanager-coupon",
            "GRIDNAME" : "BROWSE_WAAGENRABATTE",
            "FORMNAME" : "",
            "DISABLED" : false
        },
        {
            "LABEL" : "Zahlungsmittel",
            "ICON" : "posmanager-payment-method-card",
            "GRIDNAME" : "BROWSE_ZAHLUNGSMITTEL",
            "FORMNAME" : "",
            "DISABLED" : false
        }
    ];
    public static masterDataItems: any[];

    constructor(auth: AuthenticationProvider) {
        this.auth = auth;
    }

    public getGridList(): any[] {
        const sid = this.auth.getAuthToken();
        const user = this.auth.getUserId();

        if (sid) {
            const tabs = [];
            const apiTabs = this.masterDataList;
            for (let i = 0; i < apiTabs.length; i++) {
                tabs.push({
                    id: i,
                    route: `/stammdaten/${i}`,
                    name: apiTabs[i].LABEL,
                    icon: apiTabs[i].ICON,
                    gridName: apiTabs[i].GRIDNAME,
                    formName: apiTabs[i].FORMNAME,
                })
            }

            return tabs;
            // const response = await axios.post(`${this.auth.getServiceUrl()}/Stammdaten/Stammdaten_GetGridList`, {
            //     SID: sid,
            //     UserId: user
            // });
            // if (response.data.Stammdaten_GetGridList_response?.ServiceErrors) {
            //     throw new Error(response.data.Stammdaten_GetGridList_response.ServiceErrors[0].ERRORRECOMMENDATION);
            // } else {
            //     const tabs = new Array();
            //     const apiTabs = response.data.Stammdaten_GetGridList_response;
            //     for (let i = 0; i < apiTabs.length; i++) {
            //         tabs.push({
            //             id: i,
            //             route: `/stammdaten/${i}`,
            //             name: apiTabs[i].LABEL,
            //             icon: apiTabs[i].ICON,
            //             gridName: apiTabs[i].GRIDNAME,
            //             formName: apiTabs[i].FORMNAME,
            //         })
            //     }
            //
            //     return tabs;
            }
        return [];
        }
}
