import type { StoreRepository } from '@/modules/auth';

export class LocalStorageRepository implements StoreRepository {

    private readonly keyServiceUrl:any;
    private readonly keyToken:any;
    private readonly keyUserId:any;
    private readonly keyBranchId:any;

    constructor(params?: {
        serviceUrlKey?: string;
        userIdKey?: string;
        tokenKey?: string;
        branchIdKey?: string;
    }) {
        this.keyServiceUrl = params?.serviceUrlKey || 'serviceUrl';
        this.keyToken = params?.tokenKey || 'token';
        this.keyUserId = params?.userIdKey || 'userId';
        this.keyBranchId = params?.branchIdKey || 'branchId';
    }

    public getServiceUrl(): string | undefined {
        return localStorage.getItem(this.keyServiceUrl) || undefined;
    }

    public getToken(): string | undefined {
        return localStorage.getItem(this.keyToken) || undefined;
    }

    public getUserId(): string | undefined {
        return localStorage.getItem(this.keyUserId) || undefined;
    }

    public getBranchId(): string | undefined {
        return localStorage.getItem(this.keyBranchId) || undefined;
    }

    public setServiceUrl(serviceUrl: string): void {
        localStorage.setItem(this.keyServiceUrl, serviceUrl);
    }

    public setToken(token: string|undefined): void {
        localStorage.setItem(this.keyToken, token||'');
    }

    public setUserId(userId: string): void {
        localStorage.setItem(this.keyUserId, userId);
    }

    public setBranchId(branchId: string): void {
        localStorage.setItem(this.keyBranchId, branchId);
    }
}
