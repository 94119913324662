import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

const Buecherliste = (resolve: (...modules: any[]) => void) => require(['../views/buecherliste/Buecherliste.vue'], resolve);

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
    {
        path: '*',
        component: Buecherliste,
        meta: {
            loginRequired: true,
            pageTitle: 'Bücherliste',
            pageIcon: 'fa-book',
        }
    },

];


const router = new VueRouter({
    mode: 'history',
    routes: routes,
});


export default router;
